import checkIfFullFridge from "@/utils/fullFridge";

export const FB_PIXEL_ID = checkIfFullFridge() ? (process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID_FF || "") : (process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID || "")

export const pageview = () => {
  window.fbq('track', 'PageView')
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name, options = {}) => {
  window.fbq('track', name, options)
}