import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";
import { gridLayer } from "leaflet";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const breakpoints = createBreakpoints({
  sm: "40em",
  md: "52em",
  lg: "64em",
  xl: "80em",
});

const theme = extendTheme({
  fonts: {
    body: "'Roboto', sans-serif",
    heading: "'Montserrat', sans-serif",
  },
  colors: {
    blue: {
      600: "#20638f",
      500: "#2980b9",
      400: "#388dc5",
      300: "#409ad5",
      100: "#bedcf0",
    },
    green: {
      600: "#1e8449",
      500: "#27ae60",
      100: "#b3eecc",
    },
    red: {
      700: "#bf2718",
      600: "#d62c1a",
      500: "#e74c3c",
      100: "#f5a8a0",
    },
    gray: {
      500: "#b8b8b8",
      300: "#dedddd",
      200: "#ededed",
      100: "#f2f2f2",
    },
    yellow: {
      500: "#FFC21F",
    },
  },
  styles: {
    global: {
      body: {
        margin: 0,
        padding: 0,
        fontSize: "16px",
        bg: "white",
        color: "black",
        lineHeight: 1.7,
        pt: { base: "64px", sm: "76px" },
      },
      a: {
        transition: "all .25s ease-in-out",
        textDecoration: "none",
        cursor: "pointer",
      },
      img: {
        maxW: "100%",
      },
      ".svg-inline--fa": {
        w: "100%",
        h: "100%",
      },
      ".chakra-button__spinner": {
        left: "50%",
        ml: "-7.5px",
        top: "50%",
        mt: "-7.5px",
      },
      ".slick-prev, .slick-next": {
        w: "40px",
        h: "40px",
        transition: "all .25s ease-in-out",
        opacity: 0.5,
        _hover: {
          opacity: 0.7,
        },
      },
      ".slick-prev, .slick-prev:hover, .slick-prev:focus": {
        backgroundImage: `url('${publicRuntimeConfig.basePath}/chevron-left.svg')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      },
      ".slick-prev": {
        left: "-40px",
        _before: {
          d: "none",
        },
      },
      ".slick-next, .slick-next:hover, .slick-next:focus": {
        backgroundImage: `url('${publicRuntimeConfig.basePath}/chevron-right.svg')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      },
      ".slick-next": {
        right: "-40px",
        _before: {
          d: "none",
        },
      },
      ".slider-dark": {
        ".slick-list": {
          mx: "10px",
        },
        ".slick-prev, .slick-prev:hover, .slick-prev:focus": {
          backgroundImage: `url('${publicRuntimeConfig.basePath}/chevron-left-dark.svg')`,
        },
        ".slick-next, .slick-next:hover, .slick-next:focus": {
          backgroundImage: `url('${publicRuntimeConfig.basePath}/chevron-right-dark.svg')`,
        },
      },
      ".slick-dots": {
        "li button:before": {
          fontSize: "13px",
          transition: "opacity .25s ease-in-out",
        },
      },
      ".leaflet-container": {
        fontFamily: "'Roboto', sans-serif",
        ".leaflet-control-attribution": {
          pr: "10px",
          a: {
            color: "blue.500",
            fontWeight: 500,
            _hover: {
              color: "green.500",
              textDecoration: "none",
            },
            _focus: {
              textDecoration: "none",
            },
          },
        },
      },
      ".leaflet-touch .leaflet-bar": {
        border: 0,
        borderRadius: 0,
        "a, a:hover": {
          border: 0,
          borderRadius: "15px !important",
        },
        a: {
          backgroundColor: "green.500",
          color: "transparent",
          fontSize: 0,
          position: "relative",
          mb: "5px",
          width: "40px",
          height: "40px",
          lineHeight: "40px",
          padding: "5px",
          _before: {
            content: '""',
            display: "block",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "100%",
            backgroundPosition: "center center",
            backgroundSize: "25px 25px",
          },
          _hover: {
            backgroundColor: "blue.500",
          },
          _first: {
            _before: {
              backgroundImage: `url('${publicRuntimeConfig.basePath}/plus.svg')`,
            },
          },
          _last: {
            _before: {
              backgroundImage: `url('${publicRuntimeConfig.basePath}/minus.svg')`,
            },
          },
        },
      },
      ".react-datepicker-wrapper": {
        ".react-datepicker__input-container": {
          p: "10px 15px",
          borderRadius: "25px",
          fontSize: "14px",
          lineHeight: 1.4,
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "gray.100",
          transition: "border-color .25s ease-in-out",
          "&:focus-within": {
            borderColor: "blue.500",
          },
          ".react-datapicker__input-text": {
            flex: 1,
            outline: "none",
          },
          "> div": {
            alignItems: "center",
          },
        },
      },
      ".react-datepicker": {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "gray.100",
        borderRadius: "10px",
        fontFamily: "'Roboto', sans-serif",
        ".react-datepicker__triangle": {
          display: "none",
        },
        ".react-datepicker__header": {
          borderBottom: 0,
          backgroundColor: "green.500",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        },
        ".react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before":
          {
            top: "15px",
            borderColor: "white",
            opacity: 0.7,
            transition: "opacity .25s ease-in-out",
          },
        ".react-datepicker__navigation:hover *::before": {
          opacity: 1,
        },
        ".react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header, .react-datepicker__day-name, .react-datepicker__time-name":
          {
            color: "white",
          },
        ".react-datepicker__day": {
          borderRadius: "5px",
          transition: "background-color .25s ease-in-out",
        },
        ".react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover":
          {
            backgroundColor: "gray.100",
          },
        ".react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range":
          {
            backgroundColor: "blue.500",
          },
        ".react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover":
          {
            backgroundColor: "blue.600",
          },
      },
      ".modal-large": {
        ".chakra-modal__close-btn": {
          top: "19px",
        },
      },
      ".cs-overlay::before": {
        bg: "white",
      },
      ".cs-loader": {
        _after: {
          borderColor: "#2980b9 transparent transparent transparent",
        },
        _before: {
          borderColor: "gray.200",
        },
      },
      ".cs-main-container": {
        border: 0,
        ".cs-avatar": {
          "&.placeholder": {
            bg: "gray.500",
            p: "7.5px",
          },
        },
        ".cs-conversation-header": {
          bg: "green.500",
          color: "white",
          fontFamily: "'Roboto', sans-serif",
          border: 0,
          lineHeight: 1.2,
          px: "20px",
          py: "15px",
        },
        ".cs-conversation-header__content": {
          ".cs-conversation-header__user-name": {
            fontFamily: "'Montserrat', sans-serif",
            bg: "transparent",
            color: "inherit",
            fontWeight: "600",
            fontSize: "18px",
            textTransform: "uppercase",
          },
        },
        ".cs-conversation-header__info": {
          bg: "transparent",
          color: "inherit",
          opacity: 0.5,
          mt: "2px",
          _empty: {
            mt: 0,
          },
        },
        "> .cs-conversation-list": {
          boxShadow: "none",
          borderLeft: 0,
          borderTop: 0,
          borderBottom: 0,
          borderColor: "gray.200",
          flexBasis: "400px",
        },
        ".cs-conversation": {
          borderBottomWidth: "1px",
          borderBottomStyle: "solid",
          borderColor: "gray.200",
          _hover: {
            bg: "gray.100",
          },
          _focus: {
            bg: "gray.100",
          },
          _active: {
            bg: "gray.100",
          },
          "&.cs-conversation--active": {
            bg: "gray.100",
          },
          ".cs-conversation__name": {
            fontWeight: "bold",
            color: "blue.500",
            lineHeight: 1.4,
          },
          ".cs-conversation__info-content": {
            color: "black",
          },
          ".cs-conversation__last-activity-time": {
            fontWeight: "600",
            color: "gray.600",
            fontSize: "12px",
          },
          ".cs-conversation__unread-dot": {
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            bg: "green.500",
            w: "5px",
            h: "100%",
            mr: 0,
            borderRadius: 0,
            minW: 0,
            boxShadow: "none",
            perspective: 0,
            _hover: {
              _before: {
                display: "none",
              },
              _after: {
                display: "none",
              },
            },
          },
          "&.green": {
            ".cs-conversation__name": {
              color: "green.500",
            },
          },
        },
        ".cs-chat-container": {
          ".cs-message-list": {
            ".cs-message-separator": {
              color: "gray.500",
              _before: {
                bg: "gray.300",
              },
              _after: {
                bg: "gray.300",
              },
            },
            ".cs-message-list__scroll-wrapper": {
              p: "20px",
            },
            ".cs-message": {
              ".cs-message__content-wrapper": {
                py: "3px",
                px: "10px",
                borderRadius: "10px",
              },
              ".cs-message__content": {
                bg: "transparent",
                p: 0,
              },
              ".cs-message__footer": {
                fontSize: "12px",
                m: 0,
              },
            },
            ".cs-message--outgoing": {
              ".cs-message__content-wrapper": {
                bg: "blue.500",
              },
              ".cs-message__content": {
                color: "white",
              },
              ".cs-message__footer": {
                color: "blue.100",
              },
            },
            ".cs-message--incoming": {
              ".cs-message__content-wrapper": {
                bg: "gray.200",
              },
              ".cs-message__content": {
                color: "black",
              },
              ".cs-message__footer": {
                color: "gray.500",
              },
            },
          },
          ".cs-message-input": {
            borderLeft: 0,
            borderRight: 0,
            borderBottom: 0,
            borderColor: "gray.200",
          },
          ".cs-message-input__content-editor-wrapper": {
            bg: "transparent",
            px: 0,
            ".cs-message-input__content-editor-container, .cs-message-input__content-editor":
              {
                bg: "transparent",
              },
            ".cs-message-input__content-editor": {
              fontFamily: "'Roboto', sans-serif",
            },
          },
          ".cs-button": {
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            h: "25px",
            w: "50px",
            px: "12.5px",
            transition: "all .25s ease-in-out",
            svg: {
              display: "none",
            },
          },
          ".cs-button--attachment": {
            alignSelf: "center",
            backgroundImage: `url('${publicRuntimeConfig.basePath}/paperclip.svg')`,
          },
          ".cs-button--send": {
            alignSelf: "center",
            backgroundImage: `url('${publicRuntimeConfig.basePath}/send.svg')`,
          },
        },
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        textTransform: "uppercase",
        letterSpacing: "1px",
        borderRadius: "40px",
        fontWeight: 500,
        display: "inline-block",
        position: "relative",
        overflow: "hidden",
        fontFamily: "'Montserrat', sans-serif",
        transition: "all .25s ease-in-out",
      },
      sizes: {
        md: {
          fontSize: "15px",
          height: "auto",
          p: "12.5px 40px",
          lineHeight: 1.4,
        },
        sm: {
          px: "20px",
        },
      },
      variants: {
        solid: {
          bg: "red.500",
          color: "white",
          _hover: {
            bg: "red.600",
          },
          _groupHover: {
            bg: "red.600",
          },
          _focus: {
            boxShadow: "none",
            bg: "red.600",
          },
          _active: {
            bg: "red.600",
          },
          _groupActive: {
            bg: "red.600",
          },
        },
        blue: {
          bg: "blue.500",
          color: "white",
          _hover: {
            bg: "blue.600",
          },
          _groupHover: {
            bg: "blue.600",
          },
          _focus: {
            boxShadow: "none",
            bg: "blue.600",
          },
          _active: {
            bg: "blue.600",
          },
          _groupActive: {
            bg: "blue.600",
          },
        },
        green: {
          bg: "green.500",
          color: "white",
          _hover: {
            bg: "green.600",
          },
          _groupHover: {
            bg: "green.600",
          },
          _focus: {
            boxShadow: "none",
            bg: "green.600",
          },
          _active: {
            bg: "green.600",
          },
          _groupActive: {
            bg: "green.600",
          },
        },
        ghost: {
          bg: "gray.100",
          color: "black",
          _hover: {
            bg: "gray.200",
          },
          _groupHover: {
            bg: "gray.200",
          },
          _focus: {
            boxShadow: "none",
            bg: "gray.200",
          },
          _active: {
            bg: "gray.200",
          },
          _groupActive: {
            bg: "gray.200",
          },
        },
        simple: {
          d: "inline-flex",
          bg: "blue.500",
          color: "white",
          textTransform: "none",
          letterSpacing: 0,
          _hover: {
            bg: "blue.600",
          },
          _groupHover: {
            bg: "blue.600",
          },
          _focus: {
            boxShadow: "none",
            bg: "blue.600",
          },
          _active: {
            bg: "blue.600",
          },
          _groupActive: {
            bg: "blue.600",
          },
        },
      },
    },
    Heading: {
      baseStyle: {
        lineHeight: "1.2",
      },
      sizes: {
        sm: {
          fontSize: "17px",
          fontWeight: "500",
          my: "20px",
          textAlign: "center",
          color: "red.500",
        },
        md: {
          textTransform: "uppercase",
          fontSize: "20px",
          letterSpacing: "1px",
          color: "red.500",
          fontWeight: "600",
          my: "30px",
        },
        lg: {
          textAlign: "center",
          color: "green.500",
          fontSize: "35px",
          mb: { base: "30px", sm: "50px" },
          fontWeight: 600,
          _after: {
            content: '""',
            display: "block",
            w: "100px",
            h: { base: "3px", sm: "5px" },
            borderRadius: "5px",
            bg: "blue.500",
            m: { base: "15px auto 0", sm: "25px auto 0" },
          },
        },
      },
      variants: {
        blue: {
          color: "blue.500",
          _after: {
            bg: "green.500",
          },
        },
        white: {
          color: "white",
          _after: {
            bg: "white",
            opacity: 0.4,
          },
        },
        green: {
          color: "green.100",
        },
        red: {
          color: "red.500",
          _after: {
            bg: "green.500",
          },
        },
        gray: {
          color: "gray.600",
        },
        simple: {
          fontWeight: "bold",
          textAlign: "left",
          mb: "20px",
          _after: {
            display: "none",
          },
        }
      },
    },
    Input: {
      parts: ["field", "addon"],
      sizes: {
        xl: {
          field: {
            py: "15px",
            borderRadius: "30px",
            fontSize: "18px",
          },
        },
        md: {
          field: {
            paddingInlineStart: "15px",
            paddingInlineEnd: "15px",
            py: "10px",
            borderRadius: "25px",
            fontSize: "14px",
            lineHeight: 1.4,
          },
        },
      },
      variants: {
        unstyled: {
          field: {
            backgroundColor: "#FFFFFF",
            borderWidth: "1px",
            borderColor: "gray.100",
            _focus: {
              borderColor: "blue.500",
            },
            _disabled: {
              bg: "gray.100",
            },
            _readOnly: {
              cursor: "default",
              pointerEvents: "none",
            },
            _invalid: {
              borderColor: "red.500",
            },
          },
        },
      },
      defaultProps: {
        variant: "unstyled",
      },
    },
    Textarea: {
      sizes: {
        md: {
          p: "10px 15px",
          borderRadius: "25px",
          fontSize: "14px",
          lineHeight: 1.4,
        },
      },
      variants: {
        unstyled: {
          backgroundColor: "#FFFFFF",
          borderWidth: "1px",
          borderColor: "gray.100",
          _focus: {
            borderColor: "blue.500",
          },
          _disabled: {
            bg: "gray.100",
          },
          _readOnly: {
            cursor: "default",
            pointerEvents: "none",
          },
          _invalid: {
            borderColor: "red.500",
          },
        },
      },
      defaultProps: {
        variant: "unstyled",
      },
    },
    Select: {
      parts: ["field", "icon"],
      sizes: {
        md: {
          field: {
            p: "10px 15px",
            borderRadius: "25px",
            fontSize: "14px",
            lineHeight: 1.4,
          },
        },
      },
      variants: {
        unstyled: {
          field: {
            backgroundColor: "#FFFFFF",
            borderWidth: "1px",
            borderColor: "gray.100",
            _focus: {
              borderColor: "blue.500",
            },
            _disabled: {
              bg: "gray.100",
            },
            _readOnly: {
              cursor: "default",
              pointerEvents: "none",
            },
            _invalid: {
              borderColor: "red.500",
            },
          },
        },
      },
      defaultProps: {
        variant: "unstyled",
      },
    },
    Link: {
      baseStyle: {
        color: "blue.500",
        _hover: {
          color: "blue.600",
          textDecoration: "none",
        },
        _focus: {
          boxShadow: "none",
          outline: "none",
        },
      },
      variants: {
        bold: {
          fontWeight: 500,
          _hover: {
            color: "green.500",
          },
        },
      },
    },
    Accordion: {
      parts: ["container", "button", "panel", "icon"],
      baseStyle: {
        container: {
          borderTopWidth: "0px",
          _last: {
            borderBottomWidth: "0px",
          },
        },
        button: {
          borderRadius: "40px",
          bg: "blue.300",
          p: { base: "10px 20px", sm: "15px 40px" },
          mb: "20px",
          _focus: {
            boxShadow: "none",
          },
          _hover: {
            bg: "blue.400",
          },
          _expanded: {
            mb: 0,
          },
        },
        panel: {
          px: { base: "20px", sm: "40px" },
          py: "20px",
        },
        icon: {
          w: "25px",
          h: "25px",
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontWeight: 500,
        px: "5px",
        fontSize: "15px",
        color: "black",
        marginBottom: "5px",
      },
    },
    FormError: {
      parts: ["text", "icon"],
      baseStyle: {
        text: {
          marginTop: "10px",
          px: "5px",
          fontSize: "13px",
        },
      },
    },
    Form: {
      parts: ["container", "requiredIndicator", "helperText"],
      baseStyle: {
        helperText: {
          marginTop: "10px",
          px: "5px",
          fontSize: "13px",
        },
      },
    },
    Slider: {
      parts: ["container", "thumb", "track", "filledTrack"],
      baseStyle: {
        thumb: {
          boxShadow: "none",
          borderWidth: 0,
          backgroundColor: "blue.500",
          _focus: {
            boxShadow: "none",
          },
        },
        filledTrack: {
          bg: "blue.500",
        },
        container: {
          mx: "5px",
        },
      },
      defaultProps: {
        size: "lg",
      },
    },
    Modal: {
      parts: [
        "dialogContainer",
        "dialog",
        "header",
        "closeButton",
        "body",
        "footer",
      ],
      baseStyle: {
        dialog: {
          borderRadius: "25px",
        },
        header: {
          bg: "green.500",
          borderTopLeftRadius: "25px",
          borderTopRightRadius: "25px",
          fontSize: "17px",
          fontWeight: "500",
          color: "white",
          fontFamily: "'Montserrat', sans-serif",
        },
        body: {
          py: "20px",
          fontSize: "15px",
        },
      },
    },
    CloseButton: {
      baseStyle: {
        color: "white",
        opacity: 0.7,
        _focus: {
          boxShadow: "none",
          opacity: 1,
        },
        _hover: {
          bg: "transparent",
          opacity: 1,
        },
        _active: {
          bg: "transparent",
          opacity: 1,
        },
      },
    },
    Switch: {
      parts: ["track", "thumb"],
      baseStyle: {
        track: {
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },
    Tooltip: {
      baseStyle: {
        p: "5px 10px",
        borderRadius: "10px",
        fontSize: "14px",
        lineHeight: 1.4,
        bg: "green.500",
        boxShadow: "none",
        color: "white",
        fontWeight: "normal",
      },
    },
    Badge: {
      baseStyle: {
        px: "10px",
        py: "2.5px",
        borderRadius: "10px",
        fontWeight: 500,
        fontSize: "11px",
      },
      defaultProps: {
        variant: "solid",
      },
      variants: {
        solid: {
          bg: "blue.500",
        },
      },
    },
    Tabs: {
      parts: ["root", "tablist", "tab", "tabpanel", "indicator"],
      baseStyle: {},
      variants: {
        enclosed: {
          tablist: {
            mb: "-2px",
            borderBottomWidth: "2px",
            borderBottomColor: "gray.100",
          },
          tab: {
            mb: "-2px",
            fontWeight: "500",
            fontFamily: "'Montserrat', sans-serif",
            bg: "gray.100",
            fontSize: "14px",
            mr: "5px",
            _last: {
              mr: 0,
            },
            _focus: {
              boxShadow: "none",
            },
            _selected: {
              borderColor: "blue.500",
              borderBottomColor: "blue.500",
              bg: "blue.500",
              color: "white",
            },
          },
          tabpanel: {
            px: 0,
            pb: 0,
            pt: "20px",
          },
        },
      },
      defaultProps: {
        variant: "enclosed",
      },
    },
    Alert: {
      parts: ["container", "title", "description", "icon"],
      baseStyle: {
        container: {
          borderRadius: "10px",
        },
      },
      defaultProps: {
        variant: "solid",
      },
    },
    Menu: {
      parts: ["item", "command", "list", "button", "groupTitle", "divider"],
      baseStyle: {
        item: {
          fontSize: "15px",
          _hover: {
            bg: "gray.200",
          },
          _active: {
            bg: "gray.200",
          },
          _focus: {
            bg: "gray.200",
          },
        },
        list: {
          bg: "gray.100",
          boxShadow: "none",
          outline: "none",
          border: 0,
        },
      },
    },
  },
  breakpoints,
});

export default theme;
